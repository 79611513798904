const myServiceRouter = {
  route: null,
  name: null,
  title: '我的服务管理',
  type: 'folder', // 类型: folder, tab, view
  icon: 'iconfont icon-tushuguanli',
  filePath: 'view/myservice/', // 文件路径
  order: null,
  inNav: true,
  permission: ['超级管理员独有权限'],
  children: [
    {
      title: '服务列表',
      type: 'view',
      name: 'MyServiceCreate',
      route: '/myservice/list',
      filePath: 'view/myservice/myservice-list.vue',
      inNav: true,
      icon: 'iconfont icon-tushuguanli',
    },
    {
      title: '添加服务',
      type: 'view',
      name: 'MyServiceCreate',
      route: '/myservice/add',
      filePath: 'view/myservice/myservice.vue',
      inNav: true,
      icon: 'iconfont icon-add',
    },
  ],
}

export default myServiceRouter
