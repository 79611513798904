const inboundRouter = {
    route: null,
    name: null,
    title: "入库单管理",
    type: "folder", // 类型: folder, tab, view
    icon: "iconfont icon-tushuguanli",
    isElementIcon: false,
    filePath: "view/inbound/", // 文件路径
    order: null,
    inNav: true,
    permission: ['查询入库整车单'],
    children: [
      {
        title: "入库单列表",
        type: "view",
        name: "inboundCreate",
        route: "/inbound/list",
        filePath: "view/inbound/inbound-list.vue",
        inNav: true,
        icon: "iconfont icon-tushuguanli",
        isElementIcon: false,
      },
      {
        title: "添加入库单",
        type: "view",
        name: "inboundCreate",
        route: "/inbound/add",
        filePath: "view/inbound/inbound.vue",
        inNav: true,
        icon: "iconfont icon-add",
        isElementIcon: false,
      },
    ],
  };
  
  export default inboundRouter;
  