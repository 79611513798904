const bookRouter = {
    route: null,
    name: null,
    title: "入库整车单管理",
    type: "folder", // 类型: folder, tab, view
    icon: "iconfont icon-tushuguanli",
    isElementIcon: false,
    filePath: "view/inboundship/", // 文件路径
    order: null,
    inNav: true,
    permission: ['查询单个入库单'],
    children: [
      {
        title: "入库整车单列表",
        type: "view",
        name: "BookCreate",
        route: "/inboundship/list",
        filePath: "view/inboundship/ship-list.vue",
        inNav: true,
        icon: "iconfont icon-tushuguanli",
        isElementIcon: false,
      },
      {
        title: "添加入库整车单",
        type: "view",
        name: "BookCreate",
        route: "/inboundship/add",
        filePath: "view/inboundship/ship.vue",
        inNav: true,
        icon: "iconfont icon-add",
        isElementIcon: false,
        permission: ['超级管理员独有权限'],
      },
    ],
  };
  
  export default bookRouter;
  