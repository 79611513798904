const productRouter = {
  route: null,
  name: null,
  title: "产品管理",
  type: "folder", // 类型: folder, tab, view
  icon: "iconfont icon-tushuguanli",
  filePath: "view/product/", // 文件路径
  permission: ['超级管理员独有权限'],
  order: null,
  inNav: true,
  children: [
    {
      title: "产品列表",
      type: "view",
      name: "BookCreate",
      route: "/product/list",
      filePath: "view/product/product-list.vue",
      inNav: true,
      icon: "iconfont icon-tushuguanli",
    },
    {
      title: "添加产品",
      type: "view",
      name: "BookCreate",
      route: "/product/add",
      filePath: "view/product/product.vue",
      inNav: true,
      icon: "iconfont icon-add",
    },
  ],
};

export default productRouter;
